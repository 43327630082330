import { Injectable } from "@angular/core";
import { Player } from "./player.model";
import { MatchesService } from "../matches/matches.service";

@Injectable({
    providedIn: 'root'
})
export class PlayersService {

    constructor(private matchService: MatchesService) {}

    private _players: Player[] = [
        new Player("Mustafa Mir3id"),
        new Player("Yasir Jalili"),
        new Player("Baraa"),
        new Player("Ammar Jalili"),
        new Player("Hassan Abu Ali"),
        new Player("Mowaffaq"),
        new Player("Omar Gawran"),
        new Player("Doodi"),
        new Player("Firas"),
        new Player("Mustafa Kubaisi"),
        new Player("Barah"),
        new Player("Abu Manar"),
        new Player("Gara"),
        new Player("Radhwan")
    ];

    get players(): Player[] {
        return this._players;
    }

    getFullPlayers(): Player[] {
        return [
            new Player("Mustafa Mir3id", this.matchService.calculateWins("Mustafa Mir3id"), this.matchService.calculateLosses("Mustafa Mir3id")),
            new Player("Yasir Jalili", this.matchService.calculateWins("Yasir Jalili"), this.matchService.calculateLosses("Yasir Jalili")),
            new Player("Baraa", this.matchService.calculateWins("Baraa"), this.matchService.calculateLosses("Baraa")),
            new Player("Ammar Jalili", this.matchService.calculateWins("Ammar Jalili"), this.matchService.calculateLosses("Ammar Jalili")),
            new Player("Hassan Abu Ali", this.matchService.calculateWins("Hassan Abu Ali"), this.matchService.calculateLosses("Hassan Abu Ali")),
            new Player("Mowaffaq", this.matchService.calculateWins("Mowaffaq"), this.matchService.calculateLosses("Mowaffaq")),
            new Player("Omar Gawran", this.matchService.calculateWins("Omar Gawran"), this.matchService.calculateLosses("Omar Gawran")),
            new Player("Doodi", this.matchService.calculateWins("Doodi"), this.matchService.calculateLosses("Doodi")),
            new Player("Firas", this.matchService.calculateWins("Firas"), this.matchService.calculateLosses("Firas")),
            new Player("Mustafa Kubaisi", this.matchService.calculateWins("Mustafa Kubaisi"), this.matchService.calculateLosses("Mustafa Kubaisi")),
            new Player("Barah", this.matchService.calculateWins("Barah"), this.matchService.calculateLosses("Barah")),
            new Player("Abu Manar", this.matchService.calculateWins("Abu Manar"), this.matchService.calculateLosses("Abu Manar")),
            new Player("Gara", this.matchService.calculateWins("Gara"), this.matchService.calculateLosses("Gara")),
            new Player("Radhwan", this.matchService.calculateWins("Radhwan"), this.matchService.calculateLosses("Radhwan"))
        ];
    }

}