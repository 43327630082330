<div class="container">
    <div class="position-fixed">
        <div class="row no-gutters">
            <div class="col-md-12 col-sm-12">
                <p><strong>Date: {{match.matchDate | date}}</strong></p>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col">
                <app-team [team]="match.winnerTeam"></app-team>
                <div *ngIf="match.rounds">
                    <p *ngFor="let round of match.rounds.winnerRounds" class="winner-score-board"><strong>{{round}}</strong></p>
                </div>
            </div>
            <div class="col">
                <app-team [team]="match.loserTeam"></app-team>
                <div *ngIf="match.rounds">
                    <p *ngFor="let round of match.rounds.loserRounds" class="loser-score-board"><strong>{{round}}</strong></p>
                </div>
            </div>
        </div>
        <!-- <div class="row no-gutters" style="text-align: center;" *ngIf="!match.rounds">
            <div class="col-md-12 col-sm-12" style="text-align: center;"><p>Match Details Not Available</p></div>
        </div> -->
        <div class="row no-gutters" style="text-align: center;">
            <div class="col-md-12 col-sm-12" style="text-align: center;" *ngIf="!match.onGoing"><p>Win Type: <strong>{{match.winType}}</strong></p></div>
            <div class="col-md-12 col-sm-12" style="text-align: center;" *ngIf="match.onGoing"><p><strong>On Going Match</strong></p></div>
        </div>
    </div>
</div>