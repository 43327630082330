export class User {
    constructor(
        public email: string,
        public id: string,
        private _token: string,
        public refreshToken: string,
        private _tokenExpirationDate: Date
    ){}

    get token() {
        if(!this._tokenExpirationDate || new Date() > this._tokenExpirationDate){
            return null;
        }
        return this._token;
    }

    set token(newToken: string) {
        this._token = newToken;
    }

    get tokenExpirationDate(): Date {
        return this._tokenExpirationDate;
    }

    set tokenExpirationDate(newExpirationDate: Date) {
        this._tokenExpirationDate = newExpirationDate;
    }
}