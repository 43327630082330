import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button"
import { MatInputModule } from "@angular/material/input"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatListModule } from "@angular/material/list"
import { MatSelectModule } from "@angular/material/select"

@NgModule({
    imports: [MatButtonModule,
              MatIconModule,
              MatInputModule,
              MatFormFieldModule,
              MatSidenavModule,
              MatToolbarModule,
              MatListModule,
              MatSelectModule],

    exports: [MatButtonModule,
              MatIconModule,
              MatInputModule,
              MatFormFieldModule,
              MatSidenavModule,
              MatToolbarModule,
              MatListModule,
              MatSelectModule]
})
export class MaterialModule {}