import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Match, WinType } from '../matches/match.model';
import { MatchesService } from '../matches/matches.service';
import { Team, TeamStatus } from '../matches/team/team.model';
import { Player } from '../players/player.model';
import { PlayersService } from '../players/players.service';
import { CanComponentDeactivate } from './can-deactivate.guard';

@Component({
  selector: 'app-play-match',
  templateUrl: './play-match.component.html',
  styleUrls: ['./play-match.component.css']
})
export class PlayMatchComponent implements OnInit, CanComponentDeactivate {

  players: Player[];
  playMatchFormGroup: FormGroup;
  winningTeam: string = "No Team";
  losingTeam: string = "No Team";
  scoreDifference: number = 0;
  matchStarted: boolean = false;
  duplicateUserInMatch: boolean = false;
  currentRound: number = 1;
  tie: boolean = true;
  team1Konkan: boolean = false;
  team2Konkan: boolean = false;
  showTeam1KonkanButton: boolean = false;
  showTeam2KonkanButton: boolean = false;
  ongoingMatchId: string = null;

  constructor(private playersService: PlayersService,
              private matchesService: MatchesService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    
    this.players = this.playersService.players;
    if(this.players[0].playerName){
      this.players.unshift(new Player(null));
    }
    this.playMatchFormGroup = new FormGroup({
      team1Player1: new FormControl(this.players[0].playerName, [Validators.required]),
      team1Player2: new FormControl(this.players[0].playerName, [Validators.required]),
      team2Player1: new FormControl(this.players[0].playerName, [Validators.required]),
      team2Player2: new FormControl(this.players[0].playerName, [Validators.required]),
      team1Rounds: new FormArray([]),
      team2Rounds: new FormArray([])
    });
    this.loadOngoingMatchToLocalStorage();
  }

  onNewRound() {
    this.inserNewRoundControlsRow();
    this.authService.user.pipe(take(1), tap(user=>{
      this.authService.renewToken(user);
    })).subscribe();

    let winnerRounds: string[] = [];
    let loserRounds: string[] = [];
    let winnerTeam: Team;
    let loserTeam: Team;
    if(this.winningTeam === "Team1"){
      winnerRounds = this.playMatchFormGroup.get('team1Rounds').value;
      loserRounds = this.playMatchFormGroup.get('team2Rounds').value;
      winnerTeam = new Team(TeamStatus.winner,
                   new Player(this.playMatchFormGroup.get('team1Player1').value),
                   new Player(this.playMatchFormGroup.get('team1Player2').value));
      loserTeam = new Team(TeamStatus.loser,
                  new Player(this.playMatchFormGroup.get('team2Player1').value),
                  new Player(this.playMatchFormGroup.get('team2Player2').value));
    }else{
      winnerRounds = this.playMatchFormGroup.get('team2Rounds').value;
      loserRounds = this.playMatchFormGroup.get('team1Rounds').value;
      winnerTeam = new Team(TeamStatus.winner,
                   new Player(this.playMatchFormGroup.get('team2Player1').value),
                   new Player(this.playMatchFormGroup.get('team2Player2').value));
      loserTeam = new Team(TeamStatus.loser,
                  new Player(this.playMatchFormGroup.get('team1Player1').value),
                  new Player(this.playMatchFormGroup.get('team1Player2').value));
    }
    let winType: WinType;
    if(this.team1Konkan || this.team2Konkan){
      winType = WinType.Konkan;
    }else{
      winType = WinType.ByPoints;
    }
    const match = new Match(this.formatDate(new Date()),
                            winnerTeam,
                            loserTeam,
                            winType,
                            {winnerRounds: winnerRounds, loserRounds: loserRounds},
                            this.ongoingMatchId,
                            true);
    
    this.matchesService.updateOngoingMatch(match).subscribe(res=>{
      console.log("Update on going match:");
      console.log(res);
    });
    this.cacheOngoingMatchToLocalStorage();
  }

  inserNewRoundControlsRow(){
    const team1Control = new FormControl(null, [Validators.required, Validators.pattern('\\b\\d{1,3}\\b|\\bX{1}\\b|\\bKK{1}')]);
    const team2Control = new FormControl(null, [Validators.required, Validators.pattern('\\b\\d{1,3}\\b|\\bX{1}\\b|\\bKK{1}')]);
    (<FormArray>this.playMatchFormGroup.get('team1Rounds')).push(team1Control);
    (<FormArray>this.playMatchFormGroup.get('team2Rounds')).push(team2Control);
    this.currentRound = (<FormArray>this.playMatchFormGroup.get('team1Rounds')).length;
    if(this.losingTeam === "Team1"){
      this.showTeam1KonkanButton = true;
      this.showTeam2KonkanButton = false;
    }else{
      this.showTeam1KonkanButton = false;
      this.showTeam2KonkanButton = true;
    }
  }

  onTeam1Tarqa(index: number) {
    (<FormArray>this.playMatchFormGroup.get('team1Rounds')).controls[index].setValue("X");
    this.calculateResults();
  }

  onTeam2Tarqa(index: number) {
    (<FormArray>this.playMatchFormGroup.get('team2Rounds')).controls[index].setValue("X");
    this.calculateResults();
  }

  onTeam1Konkan(index: number) {
    (<FormArray>this.playMatchFormGroup.get('team1Rounds')).controls[index].setValue("KK");
    this.calculateResults();
  }

  onTeam2Konkan(index: number) {
    (<FormArray>this.playMatchFormGroup.get('team2Rounds')).controls[index].setValue("KK");
    this.calculateResults();
  }

  onTeam1ScoreChange() {
    if((<FormArray>this.playMatchFormGroup.get('team1Rounds')).controls[(<FormArray>this.playMatchFormGroup.get('team1Rounds')).controls.length -1].invalid){
      return;
    }
    this.calculateResults();
  }

  onTeam2ScoreChange() {
    if((<FormArray>this.playMatchFormGroup.get('team2Rounds')).controls[(<FormArray>this.playMatchFormGroup.get('team2Rounds')).controls.length -1].invalid){
      return;
    }
    this.calculateResults();
  }

  calculateResults() {
    let team1Score = 0;
    let team2Score = 0;
    this.team1Konkan = false;
    this.team2Konkan = false;
    (<FormArray>this.playMatchFormGroup.get('team1Rounds')).controls.forEach(control => {
      if(control.value === "X"){
        team1Score = team1Score - 25;
      }else if(control.value === "KK"){
        this.team1Konkan = true;
      }else{
        team1Score = team1Score + +control.value;
      }
    });
    (<FormArray>this.playMatchFormGroup.get('team2Rounds')).controls.forEach(control => {
      if(control.value === "X"){
        team2Score = team2Score - 25;
      }else if(control.value === "KK"){
        this.team2Konkan = true;
      }else{
        team2Score = team2Score + +control.value;
      }
    });
    if(team1Score < team2Score){
      this.tie = false;
      this.winningTeam = "Team1";
      this.losingTeam = "Team2";
      this.scoreDifference = team2Score - team1Score;
    }else if(team2Score < team1Score){
      this.tie = false;
      this.winningTeam = "Team2";
      this.losingTeam = "Team1";
      this.scoreDifference = team1Score - team2Score;
    }else{
      this.tie = true;
      this.winningTeam = "No Team";
      this.losingTeam = "No Team";
      this.scoreDifference = team1Score - team2Score;
    }
    if(this.team1Konkan){
      this.winningTeam = "Team1";
      this.losingTeam = "Team2";
    }else if(this.team2Konkan){
      this.winningTeam = "Team2";
      this.losingTeam = "Team1";
    }
    this.cacheOngoingMatchToLocalStorage();
  }

  startMatch() {
    if(this.detectDuplicatePlayer()){
      return;
    }
    
    this.inserNewRoundControlsRow();
    this.matchStarted = true;
    let winnerRounds: string[] = [];
    let loserRounds: string[] = [];
    let winnerTeam: Team;
    let loserTeam: Team;
    //there is no winner or loser yet
    winnerRounds = this.playMatchFormGroup.get('team1Rounds').value;
    loserRounds = this.playMatchFormGroup.get('team2Rounds').value;
    winnerTeam = new Team(TeamStatus.tie,
                  new Player(this.playMatchFormGroup.get('team1Player1').value),
                  new Player(this.playMatchFormGroup.get('team1Player2').value));
    loserTeam = new Team(TeamStatus.tie,
                  new Player(this.playMatchFormGroup.get('team2Player1').value),
                  new Player(this.playMatchFormGroup.get('team2Player2').value));
    
    let winType: WinType;
    if(this.team1Konkan || this.team2Konkan){
      winType = WinType.Konkan;
    }else{
      winType = WinType.ByPoints;
    }
    const newMatch = new Match(this.formatDate(new Date()),
                            winnerTeam,
                            loserTeam,
                            winType,
                            {winnerRounds: winnerRounds, loserRounds: loserRounds},
                            undefined,
                            true);
    this.matchesService.saveMatch(newMatch).subscribe(res=>{
      this.ongoingMatchId = res.name;
      this.cacheOngoingMatchToLocalStorage();
      console.log("The return of save match observable");
      console.log(res);

    });
  }

  private detectDuplicatePlayer() {
    let duplicate = false;
    this.duplicateUserInMatch = false;
    let matchPlayersArray: string[] = [];
    matchPlayersArray.push(this.playMatchFormGroup.value.team1Player1);
    matchPlayersArray.push(this.playMatchFormGroup.value.team1Player2);
    matchPlayersArray.push(this.playMatchFormGroup.value.team2Player1);
    matchPlayersArray.push(this.playMatchFormGroup.value.team2Player2);
    for(let i=0; i <= matchPlayersArray.length; i++){
      for(let j=i+1; j <= matchPlayersArray.length; j++){
        if(matchPlayersArray[i] === matchPlayersArray[j]){
          this.duplicateUserInMatch = true;
          duplicate = true;
        }
      }
    }
    return duplicate;
  }

  onEndMatch() {
    let winnerRounds: string[] = [];
    let loserRounds: string[] = [];
    let winnerTeam: Team;
    let loserTeam: Team;
    if(this.winningTeam === "Team1"){
      winnerRounds = this.playMatchFormGroup.get('team1Rounds').value;
      loserRounds = this.playMatchFormGroup.get('team2Rounds').value;
      winnerTeam = new Team(TeamStatus.winner,
                   new Player(this.playMatchFormGroup.get('team1Player1').value),
                   new Player(this.playMatchFormGroup.get('team1Player2').value));
      loserTeam = new Team(TeamStatus.loser,
                  new Player(this.playMatchFormGroup.get('team2Player1').value),
                  new Player(this.playMatchFormGroup.get('team2Player2').value));
    }else{
      winnerRounds = this.playMatchFormGroup.get('team2Rounds').value;
      loserRounds = this.playMatchFormGroup.get('team1Rounds').value;
      winnerTeam = new Team(TeamStatus.winner,
                   new Player(this.playMatchFormGroup.get('team2Player1').value),
                   new Player(this.playMatchFormGroup.get('team2Player2').value));
      loserTeam = new Team(TeamStatus.loser,
                  new Player(this.playMatchFormGroup.get('team1Player1').value),
                  new Player(this.playMatchFormGroup.get('team1Player2').value));
    }
    let winType: WinType;
    if(this.team1Konkan || this.team2Konkan){
      winType = WinType.Konkan;
    }else{
      winType = WinType.ByPoints;
    }
    const match = new Match(this.formatDate(new Date()),
                            winnerTeam,
                            loserTeam,
                            winType,
                            {winnerRounds: winnerRounds, loserRounds: loserRounds},
                            this.ongoingMatchId,
                            false);
    this.matchStarted = false;
    this.matchesService.updateOngoingMatch(match).subscribe(()=>{
      localStorage.removeItem('ongoingMatchValue');
      localStorage.removeItem('ongoingMatchId');
        this.matchesService.getMatches().subscribe(()=>{
          this.router.navigate(['/matches/0']);
        });
    });
    
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.matchStarted){
      alert("Please either End Game or Cancel Game");
      return false;
    }else{
      return true;
    }
  }

  onCancelMatch() {
    this.matchStarted = false;
    localStorage.removeItem('ongoingMatchValue');
    localStorage.removeItem('ongoingMatchId');
    this.matchesService.deleteMatch(this.ongoingMatchId);
    this.router.navigate(['/matches']);
  }

  cacheOngoingMatchToLocalStorage() {
    localStorage.setItem('ongoingMatchValue', JSON.stringify(this.playMatchFormGroup.value));
    localStorage.setItem('ongoingMatchId', JSON.stringify(this.ongoingMatchId));
  }

  loadOngoingMatchToLocalStorage() {
    let ongoingMatchData = JSON.parse(localStorage.getItem('ongoingMatchValue'));
    if(!ongoingMatchData){
      return;
    }
    this. ongoingMatchId = JSON.parse(localStorage.getItem('ongoingMatchId'));
    ongoingMatchData.team1Rounds.forEach(element => {
      this.inserNewRoundControlsRow();
    });
    this.playMatchFormGroup.patchValue(ongoingMatchData);  
    this.matchStarted = true;
    this.calculateResults();
    if(this.losingTeam === "Team1"){
      this.showTeam1KonkanButton = true;
      this.showTeam2KonkanButton = false;
    }else{
      this.showTeam1KonkanButton = false;
      this.showTeam2KonkanButton = true;
    }
  }

  saveOnGoingGameToMatches() {

  }  

}
