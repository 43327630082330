<!-- <div class="container">
    <div class="row no-gutters">
        <div>
            <button class="btn btn-primary btn-sm" (click)="onNewMatch()" *ngIf="false"><small>New Match</small></button>
            <button class="btn btn-danger btn-sm" (click)="onDeleteAll()" *ngIf="isAdmin && false"><small>Delete All</small></button>
        </div>
    </div>
</div>
<br> -->

<div fxLayout="column">
    <div *ngFor="let match of matches; let i=index">
        <app-match-item [match]="match" [index]="i"></app-match-item>
    </div>
</div>
