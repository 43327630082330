import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatchesService } from '../matches.service';
import { Match } from '../match.model';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-match-list',
  templateUrl: './match-list.component.html',
  styleUrls: ['./match-list.component.css']
})
export class MatchListComponent implements OnInit {

  matches: Match[];
  isAdmin: boolean;

  constructor(private matchesService: MatchesService,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.matchesService.getMatches().subscribe((matches)=>{
      this.matches = matches;
    })
    this.matchesService.matchesUpdated.subscribe((matches)=>{
      this.matches = matches;
    });
    this.authService.user.subscribe(user=>{
      if(user.email === 'mmmshebli@gmail.com'){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }
    });
  }

  onNewMatch() {
    this.router.navigate(["/matches/new"]);
  }

  onDeleteAll() {
    this.matchesService.clearAllMatches();
  }

}
