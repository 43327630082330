import { Component, Input, OnInit } from '@angular/core';
import { MatchesService } from '../matches/matches.service';
import { Player } from './player.model';
import { PlayersService } from './players.service';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {

  @Input() players: Player[] = [];

  constructor(private playersService: PlayersService,
              private matchesService: MatchesService) { }

  ngOnInit(): void {
    this.players = this.playersService.getFullPlayers();
    this.matchesService.matchesUpdated.subscribe(()=>{
      this.players = this.playersService.getFullPlayers();
    }); 
  }

}
