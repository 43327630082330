import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isAuthenticated: boolean = false;

  constructor(private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.authService.user.subscribe(user=>{
      if(!user){
        this.isAuthenticated = false;
      }else{
        this.isAuthenticated = true;
      }
    });
  }

  onLogout() {
    this.authService.signOut();
    this.router.navigate(['/auth']);
  }

}
