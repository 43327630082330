import { Team } from "./team/team.model";

export enum WinType {ByPoints="By Points", Konkan="Konkan"}

export class Match {
    constructor(
        public matchDate: string,
        public winnerTeam: Team,
        public loserTeam: Team,
        public winType: WinType,
        public rounds?: { 'winnerRounds': string[], 'loserRounds': string[] },
        public id?: string,
        public onGoing?: boolean
    ){}
}