<div class="container-fluid">
    <div class="row no-gutters">
        <table class="table table-bordered">
            <thead>
              <tr>
                <th>Player Name</th>
                <th>Wins</th>
                <th>Losses</th>
              </tr>
            </thead>
            <tbody *ngFor="let player of players">
              <tr>
                <td>{{player.playerName}}</td>
                <td>{{player.wins}}</td>
                <td>{{player.losses}}</td>
              </tr>
            </tbody>
          </table>
    </div>
</div>