<!-- <app-header></app-header> -->
<mat-sidenav-container>
    <mat-sidenav #sidenav class="side-navigation">
        <mat-nav-list *ngIf="isAuthenticated">
            <a mat-list-item routerLink="/matches" (click)="sidenav.close()">
                <mat-icon>dynamic_feed</mat-icon>
               <span class="nav-caption">Matches</span> 
            </a>
            <a mat-list-item routerLink="/play-match" (click)="sidenav.close()">
                <mat-icon>casino</mat-icon>
                <span class="nav-caption">New-Match</span>
            </a>
            <a mat-list-item routerLink="/players" (click)="sidenav.close()">
                <mat-icon>bar_chart</mat-icon>
                <span class="nav-caption">Players</span>
            </a>
            <a mat-list-item (click)="onLogout()" (click)="sidenav.close()">
                <mat-icon>follow_the_signs</mat-icon>
                <span class="nav-caption">Logout</span>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar class="app-toolbar" color="primary">
            <div>
                <button mat-icon-button (click)="sidenav.toggle()">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </mat-toolbar>
        <main>
            <router-outlet></router-outlet>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container>