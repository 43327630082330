import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MatchesComponent } from './matches/matches.component';
import { PlayersComponent } from './players/players.component';
import { TeamComponent } from './matches/team/team.component';
import { MatchItemComponent } from './matches/match-item/match-item.component';
import { MatchListComponent } from './matches/match-list/match-list.component';
import { MatchDetailsComponent } from './matches/match-details/match-details.component';
import { NewMatchComponent } from './matches/new-match/new-match.component';
import { MatchesResolverService } from './matches/matches-resolver.service';
import { PlayMatchComponent } from './play-match/play-match.component';
import { AuthComponent } from './auth/auth.component';
import { AuthInterceptorService } from './auth/auth.interceptor.service';
import { AuthGuard } from './auth/auth.guard';
import { CanDeactivateGuard } from './play-match/can-deactivate.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

const appRoutes: Routes = [
  {path: 'matches', component: MatchesComponent, canActivate: [AuthGuard], children:[
    {path: 'new', component: NewMatchComponent},
    {path: ':id', component: MatchDetailsComponent, resolve: [MatchesResolverService]}
  ]},
  {path: 'auth', component: AuthComponent},
  {path: 'play-match', component: PlayMatchComponent, canActivate: [AuthGuard], /*canDeactivate: [CanDeactivateGuard]*/},
  {path: 'players', component: PlayersComponent, canActivate: [AuthGuard], resolve: [MatchesResolverService]},
  {path: '**', redirectTo: '/matches'}
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MatchesComponent,
    PlayersComponent,
    TeamComponent,
    MatchItemComponent,
    MatchListComponent,
    MatchDetailsComponent,
    NewMatchComponent,
    PlayMatchComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
