<div class="container">
    <form [formGroup]="newMatchForm" (ngSubmit)="onSaveMatch()" #f="ngForm">
        <button [disabled]="f.invalid" type="submit" class="btn btn-primary">Save Match</button>
        <br>
        <br>
        <div class="form-group">
            <label for="matchDate">Match Date: </label>
            <input formControlName="matchDate" type="date" class="form-control" id="matchDate">
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header winner-header"><strong>Winner Team</strong></div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="winnerTeamPlayer1">Player 1: </label>
                            <select formControlName="winnerTeamPlayer1" id="winnerTeamPlayer1" class="form-control">
                                <option *ngFor="let player of players">{{player.playerName}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="winnerTeamPlayer2">Player 2: </label>
                            <select formControlName="winnerTeamPlayer2" id="winnerTeamPlayer2" class="form-control">
                                <option *ngFor="let player of players">{{player.playerName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header loser-header"><strong>Loser Team</strong></div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="loserTeamPlayer1">Player 1: </label>
                            <select formControlName="loserTeamPlayer1" id="loserTeamPlayer1" class="form-control">
                                <option *ngFor="let player of players">{{player.playerName}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="loserTeamPlayer2">Player 2: </label>
                            <select formControlName="loserTeamPlayer2" id="loserTeamPlayer2" class="form-control">
                                <option *ngFor="let player of players">{{player.playerName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="duplicateUserInMatch" class="help-block">Duplicate Player in match!</div>
        <br>
        <div class="form-group">
            <label for="winType">Win Type: </label>
            <select formControlName="winType" id="winType" class="form-control">
                <option *ngFor="let winType of winTypes">{{winType}}</option>
            </select>
        </div>
    </form>
</div>