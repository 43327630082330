import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { Match } from "./match.model";
import { map, tap, take, exhaustMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class MatchesService {

    matchesUpdated: Subject<Match[]> = new Subject();
    private _matches: Match[] = [];

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService
    ){}

    getMatches(): Observable<Match[]> {
        return this.http.get<Match[]>("https://konkan-878fa.firebaseio.com/matches.json").pipe(
            map(res=>{
                const matchesArray = [];
                for(const key in res){
                    let modifiedMatch = res[key];
                    modifiedMatch.id = key;
                    matchesArray.push(modifiedMatch);
                }
                return this.sortByDate(matchesArray);
            }), tap((sortedMatches)=>{
                //set local copy of sorted by date matches
                this._matches = sortedMatches;
                this.matchesUpdated.next(this._matches);
            })
        );
    }

    private sortByDate(unsorted: Match[]):Match[] {
        return unsorted.slice().sort((a:Match, b:Match)=>{
            if(a.matchDate < b.matchDate){
                return 1;
            }else{
                return -1;
            }
        });
    }

    get matches(): Match[] {
        return this._matches;
    }

    getMatch(index: string):Match {
        return this._matches[index];
    }

    saveMatch(match: Match): Observable<any>{
        return this.http.post("https://konkan-878fa.firebaseio.com/matches.json", match);
    }

    addMatch(newMatch: Match):void {
        this.saveMatch(newMatch).subscribe(response=>{
            this.getMatches().subscribe(()=>{
                this.router.navigate(['/matches/0']);
            });
        });
    }

    updateOngoingMatch(newMatch: Match): Observable<any> {
        return this.http.patch("https://konkan-878fa.firebaseio.com/matches/" + newMatch.id + "/.json", newMatch);
    }

    clearAllMatches() {
        //return this.http.delete("https://konkan-878fa.firebaseio.com/matches/0.json").subscribe();
        return this.http.delete("https://konkan-878fa.firebaseio.com/matches.json").subscribe();
    }

    deleteMatch(id: string) {
        this.http.delete("https://konkan-878fa.firebaseio.com/matches/" + id + ".json").subscribe(()=>{
            this.getMatches().subscribe(()=>{
                this.router.navigate(['/matches/0']);
            });
        });
    }

    calculateWins(playerName: string): number {
        const winsArray = this._matches.filter((match)=>{
            return (match.winnerTeam.player1.playerName === playerName || match.winnerTeam.player2.playerName === playerName);
        });
        return winsArray.length;
    
    }

    calculateLosses(playerName: string): number {
        const winsArray = this._matches.filter((match)=>{
            return (match.loserTeam.player1.playerName === playerName || match.loserTeam.player2.playerName === playerName);
        });
        return winsArray.length;
    }

}