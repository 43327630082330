import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { MatchesService } from "./matches.service";
import { Match } from "./match.model";

@Injectable({
    providedIn: 'root'
})
export class MatchesResolverService implements Resolve<Match[]> {

    constructor(private matchesService: MatchesService){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Match[]> | Match[]{
        const matches = this.matchesService.matches;
        if(matches.length === 0){
            return this.matchesService.getMatches();
        }else{
            return matches;
        }
    }

}