import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take, tap } from "rxjs/operators";
import { User } from "./user.model";

export interface AuthResponseData {
    kind: string,
    idToken: string,
    email: string,
    refreshToken: string,
    expiresIn: string,
    localId: string
}

export interface TokenRfreshResponseData {
    expires_in: string,
    token_type: string,
    refresh_token: string,
    id_token: string,
    user_id:string,
    project_id:string
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor(private http: HttpClient){}

    signOut() {
        this.user.next(null);
    }

    signIn(email: string, password: string):Observable<AuthResponseData> {
        return this.http.post<AuthResponseData>("https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAWWyOSMlofqLVVeHtYKC_ePoGvCs7Rap0",
            {
                email: email,
                password: password,
                returnSecureToken: true
            }
        ).pipe(tap(responseData =>{
            this.handleAuthentication(responseData.email, responseData.localId, responseData.idToken, responseData.refreshToken, +responseData.expiresIn);
        }));
    }

    handleAuthentication(
        email: string,
        userId: string,
        token: string,
        refreshToken: string,
        expiresIn: number
    ) {
        const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
        const user = new User(
            email,
            userId,
            token,
            refreshToken,
            expirationDate
        );
        this.user.next(user);
        localStorage.setItem('userData', JSON.stringify(user));
        setInterval(()=>{
            this.user.pipe(take(1), tap(user=>{
                this.renewToken(user);
            })).subscribe();
        },3000000);
    }

    autoLogin() {
        const userData: {
            email: string,
            id: string,
            _token: string,
            refreshToken: string,
            _tokenExpirationDate: string
        } = JSON.parse(localStorage.getItem('userData'));

        if(!userData){
            return;
        }
        const loadedUser: User = new User(userData.email, userData.id, userData._token, userData.refreshToken, new Date(userData._tokenExpirationDate));
        if(loadedUser.token){
            this.user.next(loadedUser);
        }
    }

    renewToken(user: User): void {
        this.http.post<TokenRfreshResponseData>("https://securetoken.googleapis.com/v1/token?key=AIzaSyAWWyOSMlofqLVVeHtYKC_ePoGvCs7Rap0",
            {
                grant_type: "refresh_token",
                refresh_token: user.refreshToken
            }
        ).subscribe(refreshedUser=>{
            let modifiedUser: User = user;
            modifiedUser.token = refreshedUser.id_token;
            modifiedUser.refreshToken = refreshedUser.refresh_token;
            modifiedUser.tokenExpirationDate = new Date(new Date().getTime() + +refreshedUser.expires_in * 1000);
            console.log(refreshedUser.id_token);
            console.log(refreshedUser.refresh_token);
            localStorage.setItem('userData', JSON.stringify(modifiedUser));
            this.user.next(modifiedUser);
        });  
    }
}