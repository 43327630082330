<section fxLayout="row" fxLayoutAlign="center start" class="login-form-container">
    <div *ngIf="error">Login Error</div>
    <form fxLayout="column" fxLayoutAlign="center center" #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
        <mat-form-field>
            <input matInput placeholder="E-Mail" type="text" id="email" ngModel name="email" required>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Password" type="password" id="password" ngModel name="password" required minlength="6">
        </mat-form-field>
        <div>
            <button  mat-raised-button color="primary" type="submit" [disabled]="!authForm.valid" class="btn btn-primary">Login <mat-icon>account_circle</mat-icon></button>
        </div>
    </form>
</section>
            
