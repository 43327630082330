import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'konkan';
  isAuthenticated: boolean = false;

  constructor(private authService: AuthService,
              private router: Router) {}

  ngOnInit(): void {
    this.authService.user.subscribe(user=>{
      if(!user){
        this.isAuthenticated = false;
      }else{
        this.isAuthenticated = true;
      }
    });
    this.authService.autoLogin();
  }

  onLogout() {
    this.authService.signOut();
    this.router.navigate(['/auth']);
  }
}
