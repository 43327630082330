import { Player } from "../../players/player.model";

export enum TeamStatus {winner="Winner", loser= "Loser", tie= "Tie"}

export class Team {
    constructor(
        public teamStatus: TeamStatus,
        public player1: Player,
        public player2: Player
    ){}
}