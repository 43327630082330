import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Player } from '../../players/player.model';
import { PlayersService } from '../../players/players.service';
import { Match } from '../match.model';
import { MatchesService } from '../matches.service';
import { Team, TeamStatus } from '../team/team.model';

@Component({
  selector: 'app-new-match',
  templateUrl: './new-match.component.html',
  styleUrls: ['./new-match.component.css']
})
export class NewMatchComponent implements OnInit {

  winTypes: string[] = [null, "By Points", "Konkan"];
  players: Player[];
  duplicateUserInMatch: boolean = false;
  newMatchForm: FormGroup;

  constructor(private playersService: PlayersService,
              private matchesService: MatchesService) { }

  ngOnInit(): void {
    this.players = this.playersService.players;
    this.players.unshift(new Player(null));
    this.newMatchForm = new FormGroup({
      matchDate: new FormControl(this.formatDate(new Date()), [Validators.required]),
      winnerTeamPlayer1: new FormControl(this.players[0], [Validators.required]),
      winnerTeamPlayer2: new FormControl(this.players[0], [Validators.required]),
      loserTeamPlayer1: new FormControl(this.players[0], [Validators.required]),
      loserTeamPlayer2: new FormControl(this.players[0], [Validators.required]),
      winType: new FormControl(null, [Validators.required]),
    });
  }

  onSaveMatch() {
    if(!this.validateForm()){
      return;
    }
    this.validateForm();
    let formValue = this.newMatchForm.value;
    let winnerTeam = new Team(TeamStatus.winner, new Player(formValue.winnerTeamPlayer1), new Player(formValue.winnerTeamPlayer2));
    let loserTeam = new Team(TeamStatus.loser, new Player(formValue.loserTeamPlayer1), new Player(formValue.loserTeamPlayer2));
    let newMatch = new Match(formValue.matchDate, winnerTeam, loserTeam, formValue.winType);
    this.matchesService.addMatch(newMatch);
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  private validateForm() {
    let valid = true;
    this.duplicateUserInMatch = false;
    let matchPlayersArray: string[] = [];
    matchPlayersArray.push(this.newMatchForm.value.winnerTeamPlayer1);
    matchPlayersArray.push(this.newMatchForm.value.winnerTeamPlayer2);
    matchPlayersArray.push(this.newMatchForm.value.loserTeamPlayer1);
    matchPlayersArray.push(this.newMatchForm.value.loserTeamPlayer2);
    for(let i=0; i <= matchPlayersArray.length; i++){
      for(let j=i+1; j <= matchPlayersArray.length; j++){
        if(matchPlayersArray[i] === matchPlayersArray[j]){
          this.duplicateUserInMatch = true;
          valid = false;
        }
      }
    }
    return valid;
  }

}
