<form [formGroup]="playMatchFormGroup" #f="ngForm" fxLayout="column" fxLayoutAlign="start center">
    <br *ngIf="!matchStarted">
    <div style="text-align: center;" *ngIf="!matchStarted">
        <button type="button" mat-raised-button color="primary" [disabled]="!f.valid || matchStarted" (click)="startMatch()">Start Match <mat-icon>casino</mat-icon></button>
    </div>
    <div *ngIf="duplicateUserInMatch" class=".help-block">Duplicate player in match!</div>
    <br *ngIf="!matchStarted">
    <div [hidden]="matchStarted" fxLayout="row" fxLayoutAlgin="center center" fxLayoutGap="5px">
        <div class="team-card">
            <div class="team-card-head"><strong>Team1</strong></div>
            <div class="team-card-body" fxLayout="column" fxLayoutAlign="center center">
                <mat-form-field class="some-padding">
                    <mat-select formControlName="team1Player1" placeholder="Player 1">
                        <mat-option *ngFor="let player of players" [value]="player.playerName">{{player.playerName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="some-padding">
                    <mat-select formControlName="team1Player2" placeholder="Player 2">
                        <mat-option *ngFor="let player of players" [value]="player.playerName">{{player.playerName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="team-card">
            <div class="team-card-head"><strong>Team2</strong></div>
            <div class="team-card-body" fxLayout="column" fxLayoutAlign="center center">
                <mat-form-field class="some-padding">
                    <mat-select formControlName="team2Player1" placeholder="Player 1">
                        <mat-option *ngFor="let player of players" [value]="player.playerName">{{player.playerName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="some-padding">
                    <mat-select formControlName="team2Player2" placeholder="Player 2">
                        <mat-option *ngFor="let player of players" [value]="player.playerName">{{player.playerName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div style="padding-top: 5px; padding-bottom: 8px;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="110px">
        <div *ngIf="matchStarted" style="color:#3f51b5;">
            <div style="text-align: center;"><strong>Team 1</strong></div>
            <strong>{{playMatchFormGroup.get('team1Player1').value}} /<br> {{playMatchFormGroup.get('team1Player2').value}}</strong>
        </div>
        <div *ngIf="matchStarted" style="color:#3f51b5;">
            <div style="text-align: center;"><strong>Team 2</strong></div>
            <strong>{{playMatchFormGroup.get('team2Player1').value}} /<br> {{playMatchFormGroup.get('team2Player2').value}}</strong>
        </div>
    </div>
    <div *ngIf="matchStarted" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="90px">
            <div formArrayName="team1Rounds" fxLayout="column" fxLayoutAlign="center start">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngFor="let control of playMatchFormGroup.get('team1Rounds').controls; let i=index; let la=last">
                    <button mat-mini-fab color="primary" type="button" (click)="onTeam1Tarqa(i)">X</button>
                    <mat-form-field class="round-field">
                        <input type="text" matInput [formControlName]="i" (keyup)="onTeam1ScoreChange()" autocomplete="off">
                    </mat-form-field>
                    <button mat-mini-fab color="accent" type="button" *ngIf="showTeam1KonkanButton && i>=4 && la && !tie" (click)="onTeam1Konkan(i)"><small>konkan</small></button>
                </div>
            </div>
            <div formArrayName="team2Rounds" fxLayout="column" fxLayoutAlign="center end">
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" *ngFor="let control of playMatchFormGroup.get('team2Rounds').controls; let i=index; let la=last">
                    <button  mat-mini-fab color="accent" type="button" *ngIf="showTeam2KonkanButton && i>=4 && la && !tie" (click)="onTeam2Konkan(i)"><small>konkan</small></button>
                    <mat-form-field class="round-field">
                        <input type="text" matInput [formControlName]="i" (keyup)="onTeam2ScoreChange()" autocomplete="off">
                    </mat-form-field>
                    <button mat-mini-fab color="primary" type="button" (click)="onTeam2Tarqa(i)">X</button>
                </div>
            </div>
        </div>
        <br>
        <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [disabled]="!f.valid" type="button" (click)="onNewRound()">New Round <mat-icon>fiber_new</mat-icon></button>
        </div>
        <div class="result-block" fxLayout="row" fxLayoutAlign="center center" *ngIf="!team1Konkan && !team2Konkan">
            <h5>
                Current Round: {{currentRound}}<br>
                <span style="color: rgb(255, 64, 129);"><strong>{{winningTeam}}</strong></span> winning 
                by: <span style="color: rgb(255, 64, 129);"><strong>{{scoreDifference}}</strong></span><br>
                <span *ngIf="!tie">{{losingTeam}} needs Tarqa + {{scoreDifference - 25 + 1}} to win</span>
            </h5>
        </div>
        <div class="result-block" fxLayout="row" fxLayoutAlign="center center" *ngIf="team1Konkan || team2Konkan">
            <h5>
                Round: {{currentRound}} <br>
                <span style="color: rgb(255, 64, 129);"><strong>{{winningTeam}}</strong></span> IS THE WINNER!<br>
                <h3 style="color:rgb(255, 64, 129);"><strong>KONKAN!!!</strong></h3>
            </h5>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
            <button mat-raised-button type="button" color="primary" (click)="onEndMatch()">END MATCH <mat-icon>save</mat-icon></button>
            <button mat-raised-button type="button" color="warn" type="button" (click)="onCancelMatch()">Cancel Match <mat-icon>cancel</mat-icon></button>
        </div>
    </div>
</form>
<br>
<br>