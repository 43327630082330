import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  error: string = null;

  constructor(private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    if(!form.value){
      return;
    }
    const email = form.value.email;
    const password = form.value.password;
    this.authService.signIn(email, password).subscribe(responseData=>{
      console.log(responseData);
      this.router.navigate(['/matches']);
    }, error=>{
      console.log(error);
      this.error = error.message;
    });
    form.reset();
  }

}
